<div class="topbar">
  <nav class="navbar-custom" id="navbar-custom">
    <ul class="list-unstyled topbar-nav float-end mb-0">
      <li>
        <button type="button" class="btn btn-primary" (click)="triggerModal(modalData)">Create New Appointment</button>
      </li>
      <li class="dropdown m-lg-5">
        <a class="nav-link dropdown-toggle nav-user" data-bs-toggle="dropdown" href="#" role="button"
           aria-haspopup="false" aria-expanded="false">
          <div class="d-flex align-items-center">
            <div>
              <small class="d-none d-md-block font-11">Welcome</small>
              <span class="d-none d-md-block fw-semibold font-12">{{doctor_name}} <i
                class="mdi mdi-chevron-down"></i></span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <a class="dropdown-item" (click)="profileInfo()"><i
            class="ti ti-user font-16 me-1 align-text-bottom"></i>Profile</a>
          <div class="dropdown-divider mb-0"></div>
          <a class="dropdown-item" (click)="logout()"><i class="ti ti-power font-16 me-1 align-text-bottom"></i>Logout</a>
        </div>
      </li>
      <button class="nav-link button-menu-mobile nav-icon" (click)="homePageLink()">
        <i class="fas fa-home"></i>
      </button>

      <li class="notification-list">
        <a class="nav-link arrow-none nav-icon offcanvas-btn" href="#" data-bs-toggle="offcanvas"
           data-bs-target="#Appearance" role="button" aria-controls="Rightbar">
          <i class="ti ti-adjustments-horizontal"></i>
        </a>
      </li>
    </ul>
    <ul class="list-unstyled topbar-nav mb-0">
      <li>
        <button class="nav-link button-menu-mobile nav-icon" id="togglemenu" (click)="toggleMenu()">
          <i class="ti ti-menu-2"></i>
        </button>
      </li>
      <li class="hide-phone app-search">
        <form role="search" action="#" method="get">
          <input type="search" name="search" class="form-control top-search mb-0" placeholder="Type text...">
          <button type="submit"><i class="ti ti-search"></i></button>
        </form>
      </li>
    </ul>
  </nav>
</div>
<ng-template #modalData let-modal>
  <div class="modal-header d-flex justify-content-between">
    <div>
      <h4 class="modal-title" id="modal-basic-title">Create New Appointment</h4>
    </div>
    <div class="control-button">
      <button type="button" class="btn btn-sm  btn-outline-secondary" (click)="modal.close('Save click')">
        <i class="ti ti-circle-x main"></i>
      </button>
      <button type="button" class="btn btn-sm  btn-outline-secondary" [disabled]="!patients_form.valid"
              (click)="createNewAndLink(false)">
        <i class="ti ti-device-floppy main"></i>
      </button>
      <button type="button" class="btn btn-sm  btn-outline-secondary" [disabled]="!patients_form.valid"
              (click)="createNewAndLink(true)">
        <i class="ti ti-player-track-next main"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="card-body">
      <div class="card text-center">
        <div class="card-header">
          <h4 class="card-title">Slot Information</h4>
        </div><!--end card-header-->
        <div class="card-body">
          <p class="card-text text-muted ">Hospital : {{slot.hospital}} | Date : {{slot.date}} | Slot
            : {{slot.start_time}} - {{slot.end_time}}</p>
        </div><!--end card-body-->
      </div>
      <ul class="nav nav-pills nav-justified" role="tablist">
        <li class="nav-item waves-effect waves-light">
          <a class="nav-link active" data-bs-toggle="tab" href="#home-1" role="tab" aria-selected="true">Existing
            Patient</a>
        </li>
        <li class="nav-item waves-effect waves-light">
          <a class="nav-link" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="false">New Patient</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane p-3 active" id="home-1" role="tabpanel">
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Search by Name, Phone No, Med ID, NIC"
                   [(ngModel)]="keyword">
            <button class="btn btn-secondary" type="button" id="button-addon2" (click)="search()">Search</button>
          </div>
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <thead>
              <tr>
                <th>Med Id</th>
                <th>Patient Name</th>
                <th>NIC</th>
                <th>Phone</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of search_results">
                <th scope="row">{{item.patient_id}}</th>
                <td>{{item.name}}</td>
                <td>{{item.nic}}</td>
                <td>{{item.mobile}}</td>
                <td>
                  <button class="btn btn-sm btn-primary" (click)="updateAppointment(item)">Create</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tab-pane p-3" id="profile-1" role="tabpanel">
          <form [formGroup]="patients_form" class="form-styles-popup">
            <div class="form-group row mb-2">
              <label>Name </label>
              <div class="col-lg-10">
                <input placeholder="Enter Name" name="name" type="text" class="form-control" formControlName="name">
                <div class="wrning-tag" *ngIf="patients_form.controls['name'].invalid">
                  Please Enter Name*
                </div>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>NIC </label>
              <div class="col-lg-10">
                <input placeholder="Enter NIC" name="nic" type="text" class="form-control" formControlName="nic">
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Phone Number </label>
              <div class="col-lg-10">
                <input placeholder="Enter Mobile " name="mobile" type="number" class="form-control"
                       formControlName="mobile">
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>DOB </label>
              <div class="col-lg-10">
                <input placeholder="Enter Department" name="dob" type="date" class="form-control" formControlName="dob">
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Gender</label>
              <div class="col-lg-10">
                <select class="form-select" formControlName="gender" placeholder="Select Gender">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Not Specified" selected>Not Specified</option>
                </select>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Address</label>
              <div class="col-lg-10">
                <input placeholder="Enter Address" name="address" type="text" class="form-control"
                       formControlName="address">
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>City </label>
              <div class="col-lg-10">
                <input placeholder="Enter City" name="city" type="city" class="form-control" formControlName="city">
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Country </label>
              <div class="col-lg-10">
                <select2 [data]="filtered_countries_select" [value]="countrySelected"
                         formControlName="country" placeholder="Select country"></select2>
              </div>
            </div>
            <div class="form-group row mb-2">
              <div class="col-lg-3">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
